import React, { useState } from "react";
import axios from "axios";
import { Box, Button, Typography, Paper, Stack, Snackbar, Alert } from "@mui/material";
import { useOnboardingLandlords } from "services/hooks/rentals";
import OnboardingLandlordsTable from "components/Tables/OnbordingLandlords";

export default function OnboardingLandlords() {
 

  // Use custom hook to fetch referred landlords data
  const { onboardingLandlords, isLoadingOnboardingLandlords, errorOnboardingLandlords } = useOnboardingLandlords();
 

  return (
    <Box m="20px">
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", mb: 5, fontSize: 25 }}
        >
          Pending Onbording Landlords
        </Typography>
      </Stack>
      <Paper sx={{ marginTop: "1rem", overflow: "hidden" }}>
        <OnboardingLandlordsTable
         onboardingLandlords={onboardingLandlords}
        />
      </Paper>
    </Box>
  );
}
