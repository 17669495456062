import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  user: null,
  token: null,
  refreshCount: 0,
  broker: {},
  manager: {},
  landlord: {},
  isAdmin: 0,
  managerDetails: {},
  property_id: null,
  tenantOccupancy: {},
  tenantPayment: {},
  currentTab: {
    settingsTab: 0,
    managementTab: 0,
    landlordsTab: 0,
    generalPropertiesTab: 0,
    generalLandlordsTab:0,
    propertyTab:0,
  }
};

export const manager_id = 1;

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAdmin = action.payload.admin
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
      state.isAdmin = null;
      state.currentTab = {
        settingsTab: 0,
        managementTab: 0,
        landlordsTab: 0,
        generalPropertiesTab: 0,
        propertyTab:0,
        generalLandlordsTab:0
      }
    },
    setRefreshCount: (state, action) => {
      state.refreshCount = action.payload; // Added reducer for refresh count
    },
    setBroker: (state, action) => {
      state.broker = action.payload;
    },
    setManager: (state, action) => {
      state.manager = action.payload;
    },
    setLandlord: (state, action) => {
      state.landlord = action.payload;
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    setManagerDetails: (state, action) => {
      state.managerDetails = action.payload;
    },
    setTenantOccupancy: (state, action) => {
      state.tenantOccupancy = action.payload;
    },
    setTenantPayment: (state, action) => {
      state.tenantPayment = action.payload;
    },
    setPropertyId: (state, action) => {
      state.property_id = action.payload
    },
    setCurrentTab: (state, action)=>{
      state.currentTab[action.payload.name] = action.payload.tab;
    },
  },
});

export const { setMode, setLogin, setLogout, setRefreshCount, setBroker, setManager, setLandlord, setIsAdmin, setManagerDetails, setTenantOccupancy, setTenantPayment, setPropertyId, setCurrentTab } = authSlice.actions;
export default authSlice.reducer;
