import React from 'react';
import { DialogContent, TextField, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { useLandlordProperties } from "services/hooks/rentals";
import { useSelector } from "react-redux";
import FlexBetween from 'components/FlexBetween';
import axios from 'axios';
import { baseURL } from 'services/API';

const ExpenseForm = ({ formData, handleFormChange, expenseTypes, errors }) => {
    const landlord = useSelector((state) => state.landlord); // Select the landlord stored in the global state
    const {landlordProperties,loading, error} = useLandlordProperties(landlord.id);
    const [selectedExpenseDetails, setSeletedExpenseDetails] = React.useState([]);
    
    const fetchData = async (type) => {
      try {
        const response = await axios.get(
          `${baseURL}/admin/expense_details?type_id=${type}`
        );
        setSeletedExpenseDetails(response.data.data);
      } catch (e) {
        return
      }
      return true;
    };

    if (loading) {
        return <div>Loading rentals...</div>;
    }

    if (error) {
        return <div>Error loading rentals</div>;
    }

    return (
      <DialogContent>
        <TextField
          name="related_landlord_rental"
          fullWidth
          value={formData.related_landlord_rental}
          error={Boolean(errors.related_landlord_rental)}
          helperText={errors.related_landlord_rental}
          onChange={handleFormChange}
          select
          label="Related Landlord Rental"
        >
          {landlordProperties.map((rental) => (
            <MenuItem key={rental.id} value={rental.id}>
              {rental.rental_name}
            </MenuItem>
          ))}
        </TextField>
        {/* <TextField
                autoFocus
                margin="dense"
                label="Related Ticket"
                type="text"
                fullWidth
                variant="outlined"
                name="related_ticket"
                value={formData.related_ticket || ""}
                error={Boolean(errors.related_landlord_rental)}
                helperText={errors.related_landlord_rental}
                onChange={handleFormChange}
            /> */}
        <TextField
          margin="dense"
          label="Amount"
          type="number"
          fullWidth
          variant="outlined"
          name="amount"
          value={formData.amount}
          error={Boolean(errors.amount)}
          helperText={errors.amount}
          onChange={handleFormChange}
        />
        <FlexBetween>
          <TextField
          margin="dense"
          label="Expense Type"
          select
          sx={{ width: "49%" }}
          variant="outlined"
          name="expense_type"
          error={Boolean(errors.expense_type)}
          helperText={errors.expense_type}
          value={formData.expense_type}
          onChange={(e, value) => {
            handleFormChange(e);
            fetchData(e.target.value);
          }}
        >
          {expenseTypes.map((item, index) => {
            return (
              <MenuItem key={item.id} value={item.id}>{item.expense_type_name}</MenuItem>
            );
          })}
        </TextField>

        <TextField
          margin="dense"
          label="Expense Category"
          type="text"
          sx={{ width: "49%" }}
          select
          variant="outlined"
          name="expense_category"
          value={formData.expense_category}
          error={Boolean(errors.expense_category)}
          helperText={errors.expense_category}
          onChange={handleFormChange}
        >
          {selectedExpenseDetails.map((item, index) => {
            return (
              <MenuItem key={item.id} value={item.id}>{item.expense_detail}</MenuItem>
            );
          })}
        </TextField>

        </FlexBetween>
        
        <TextField
          margin="dense"
          label="Description"
          type="text"
          fullWidth
          variant="outlined"
          name="description"
          value={formData.description}
          error={Boolean(errors.description)}
          helperText={errors.description}
          onChange={handleFormChange}
        />
      </DialogContent>
    );
};

export default ExpenseForm;
