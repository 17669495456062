import React, {useState, useEffect} from "react";
import { Grid, Box, Breadcrumbs, Link, Typography } from "@mui/material";
import FormDialog from "components/modal";
import SettingsPill from "components/SettingsPill";
import { Divider } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { baseURL, imageURL } from "services/API";
import axios from "axios";

export default function OnboardingConfirmation() {
  const navigate = useNavigate();
  const [openDocument, setOpenDocument] = useState(false);
  const [openBackID, setOpenBackID] = useState(false);
  const [openFrontID, setOpenFrontID] = useState(false);
	const [businessTerms, setBusinessTerms] = useState({});

  const token = useSelector((state) => state.token);
  const landlord = useSelector((state) => state.landlord); 

  useEffect(() => {
    const checkDetails = async () => {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      const response = await axios.get(
        `${baseURL}/landlords/details/${landlord.id}`
      );
      setBusinessTerms(response.data.business_terms);
    };
    checkDetails();
  }, []);

  const handleClickOpenDocument = () => {
		setOpenDocument(true);
	};

	const handleCloseDocument = () => {
		setOpenDocument(false);
	};

  

  const handleClickOpenBackID = () => {
		setOpenBackID(true);
	};

	const handleCloseBackID = () => {
		setOpenBackID(false);
	};

  const handleClickOpenFrontID = () => {
		setOpenFrontID(true);
	};

	const handleCloseFrontID = () => {
		setOpenFrontID(false);
	};

  const handleSubmitDocumentApproval = () => {
      
  }

  const handleSubmitDocumentReject= () => {
      
  }

  const handleSubmitImageApproval = () => {
      
  }

  const handleSubmitImageReject = () => {};


	return (
    <Box m="20px">
      <Breadcrumbs>
        <Link
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/landlords")}
        >
          <BusinessIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          <Typography>Landlord</Typography>
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <b>Onboarding Landlord Documents</b>
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1>{landlord.landlord_name} Landlord Documents</h1>
          <h3>RENT COLLECTION SERVICE AGREEMENT</h3>
          <Box sx={{ border: "1px solid black", padding: "10px" }}>
            <SettingsPill
              pillTitle={"TERMS OF BUSINESS"}
              pillDescription={
                "Review the Signed working terms of business document"
              }
              buttonText={"View Document"}
              buttonAction={handleClickOpenDocument}
            />
            <Divider />
          </Box>

          <h3>NATIONAL IDENTITY CARD</h3>
          <Box sx={{ border: "1px solid black", padding: "10px" }}>
            <SettingsPill
              pillTitle={"Front of ID"}
              pillDescription={
                "Review the a photo of the FRONT part of your National ID"
              }
              buttonText={"Review ID"}
              buttonAction={handleClickOpenFrontID}
            />
            <Divider />
            <SettingsPill
              pillTitle={"Back of ID"}
              pillDescription={
                "Review the a photo of the FRONT part of your National ID"
              }
              buttonText={"Review ID"}
              buttonAction={handleClickOpenBackID}
            />
            <Divider />
          </Box>
        </Grid>
      </Grid>
      <FormDialog
        open={openDocument}
        handleClose={handleCloseDocument}
        title={"Rent Collection Agreement"}
        handleSubmit={handleSubmitDocumentApproval}
        buttonText="Approve"
        handleSubmit2={handleSubmitDocumentReject}
        buttonText2="Reject"
        enableActions={true}
        buttonClass={"add-button"}
        content={<></>}
      />

      <FormDialog
        open={openFrontID}
        handleClose={handleCloseFrontID}
        title={"Front ID Image"}
        handleSubmit={handleSubmitImageApproval}
        buttonText="Approve"
        handleSubmit2={handleSubmitImageReject}
        buttonText2="Reject"
        enableActions={true}
        buttonClass={"delete-button"}
        content={
          <img
            src={imageURL + businessTerms.id_front_document}
            width="300"
            height="300"
          />
        }
      />

      <FormDialog
        open={openBackID}
        handleClose={handleCloseBackID}
        title={"Back ID Image"}
        handleSubmit={handleSubmitImageApproval}
        buttonText="Approve"
        handleSubmit2={handleSubmitImageReject}
        buttonText2="Reject"
        enableActions={true}
        buttonClass={"delete-button"}
        content={
          <img
            src={imageURL + businessTerms.id_back_document}
            width="500"
            height="300"
          />
        }
      />
    </Box>
  );
}
