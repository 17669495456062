import React, { useState, useEffect } from "react";
import axios from "axios";
import FormDialog from "components/modal";
import { baseURL } from "services/API";
import {Box,Button,Typography,Grid,styled,} from "@mui/material";
import LandlordTenantPaymentsTable from "components/Tables/LandlordTenantPaymentsTable";
import LandlordTenantPaymentsForm from "components/Forms/LandlordTenantPaymentsForm"
import {  useGetTenantOccupancyActivePeriodsData, useMonthsAndYears, useTenantPayments } from "services/hooks/rentals";
import { setRefreshCount } from "state";
import { useSelector, useDispatch } from "react-redux";
import BasicCard from "components/Card";
import FlexBetween from "components/FlexBetween";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter";
import moment from "moment";
import * as yup from 'yup';
import EditTenantPaymentForm from "components/Forms/EditLandlordTenantPaymentsForm";
import TopSnack from "components/TopSnack";
import DummyTable from "components/Tables/DummyTable";

// Style definition for the title container
const TitleContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const LandlordTenantsPayments = () => {
  const [page, setPage] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [newPayment, setNewPayment] = useState({
    related_rental_unit: "",
    related_tenant: "",
    amount: undefined,
    related_month: "",
    payment_method: "",
    date_paid: moment().format('YYYY-MM-DD'),
  });

  const dispatch = useDispatch()

  const [amount, setAmount] = useState(newPayment.amount)
  const [month, setMonth] = useState(newPayment.related_month)
  const [paymentMethod, setPaymentMethod] = useState(newPayment.payment_method)
  const [datePaid, setDatePaid] = useState(newPayment.date_paid)
  const [initialPayment, setInitialPayment] = React.useState(false);
  const [hasSecurityDeposit, setHasSecurityDeposit] = React.useState(false);
  const [nextPaymentDate, setNextPaymentDate] = useState("")
  const [errors, setErrors] = useState({})

  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const [monthOptions, setMonthOptions] = useState([]);

  const landlord = useSelector((state) => state.landlord);
  const tenantOccupancy = useSelector((state) => state.tenantOccupancy);
  const {tenantPayments, isLoadingTenantPayments, errorTenantPayments} = useTenantPayments(tenantOccupancy.id)
  const {activePeriods, isLoadingActivePeriods, errorLoadingActivePeriods} = useGetTenantOccupancyActivePeriodsData(tenantOccupancy.id)
  const { monthsAndYears, isLoadingMonthsAndYears } = useMonthsAndYears();
  const [message, setMessage] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [severity, setSeverity] = React.useState("");
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack({ ...openSnack, open: false });
  };


	const handleChangeAmount = (event) => {
    const {value} = event.target
		setAmount(value);
    if (!value.trim()) {
      setErrors(prev=> ({...prev , 'amount': 'This is required'}))
    } else {
      setErrors((prev) => ({ ...prev, amount: "" }));
    }
	};

	const handleChangeMonth = (event) => {
    const {value} = event.target
		setMonth(value);
    if (!toString(value).trim()) {
      setErrors((prev) => ({ ...prev, related_month: "This is required" }));
    } else {
      setErrors((prev) => ({ ...prev, related_month: "" }));
    }
	};

  const handleChangeDatePaid = (event) => {
    const {value} = event.target
		setDatePaid(value);
	};

  const handleChangeNextPaymentDate = (event) => {
    const {value} = event.target
		setNextPaymentDate(value);
	};

	const handleChangePaymentMethod = (event) => {
    const {value} = event.target
		setPaymentMethod(value);
    if (!toString(value).trim()) {
      setErrors((prev) => ({ ...prev, payment_method: "This is required" }));
    } else {
      setErrors((prev) => ({ ...prev, payment_method: "" }));
    }
	};

	const handleInitialPayment = (event) => {
		setInitialPayment(event.target.checked);
	};

  const handleSecurityDeposit = (event) => {
		setHasSecurityDeposit(event.target.checked);
	};

  const rowsPerPage = 10;

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewPayment({
      related_rental_unit: "",
      related_tenant: "",
      amount: undefined,
      related_month: "",
      payment_method: "",
      date_paid: "",
    });
    setInitialPayment(false);
    setHasSecurityDeposit(false);

  };

  const handleOpenEditDialog = (payment) => {
    setNewPayment(payment)
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setNewPayment({
      related_rental_unit: "",
      related_tenant: "",
      amount: undefined,
      related_month: "",
      payment_method: "",
      date_paid: "",
    });
    setInitialPayment(false);
    setHasSecurityDeposit(false);
  };

  const handleOpenDeleteDialog = (payment) => {
    setNewPayment(payment)
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setNewPayment({
      related_rental_unit: "",
      related_tenant: "",
      amount: undefined,
      related_month: "",
      payment_method: "",
      date_paid: "",
    });
    setInitialPayment(false);
    setHasSecurityDeposit(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewPayment((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (monthsAndYears) {
      setMonthOptions(
        monthsAndYears.map((monthYear) => ({
          value: monthYear.id,
          label: `${monthYear.month} ${monthYear.year}`,
        }))
      );
    }
  }, [monthsAndYears]);

  const paymentSchema = yup.object().shape({
    related_rental_unit: yup.number().required('This is required'),
    related_tenant: yup.number().required('This is required'),
    amount: yup.number().required('This is required'),
    related_month: yup.string().required('This is required'),
    payment_method: yup.string().required('This is required'),
    date_paid: yup.date().required('This is required'),
  });

  const handleSubmitTenantPayment = async () => {
    try {
			await paymentSchema.validate(
        {
          related_rental_unit: tenantOccupancy.related_rental_unit.id,
          related_tenant: tenantOccupancy.related_tenant.id,
          amount: amount,
          related_month: month,
          date_paid: datePaid,
          payment_method: paymentMethod,
          is_initial_payment: initialPayment,
          has_security_deposit: hasSecurityDeposit,
          next_payment_date: nextPaymentDate,
        }, {abortEarly:false});
		} catch (error) {
			const newError = {}
			error.inner.forEach(err => {
				newError[err.path]=err.message
			})
			setErrors(newError)
			return
		}
		try {
      const response = await axios.post(`${baseURL}/tenants/payments/custom`, {
        related_rental_unit: tenantOccupancy.related_rental_unit.id,
        related_tenant: tenantOccupancy.related_tenant.id,
        amount: amount,
        related_month: month,
        date_paid: datePaid,
        payment_method: paymentMethod,
        is_initial_payment: initialPayment,
        has_security_deposit: hasSecurityDeposit,
        next_payment_date: nextPaymentDate,
      });
      dispatch(setRefreshCount())
			setOpenDialog(false);
			setOpenSnack({ ...openSnack, open: true});
			setSeverity("success");
			setMessage("Tenant Added Successfully");
		} catch (err) {
			setOpenSnack({ ...openSnack, open: true});
			setSeverity("error");
			setMessage("Tenant Addition failed, Please check data provided");
		}
	};

  const handleSubmitEdittedTenantPayment = async () => {
    try {
      await paymentSchema.validate(
        {
          related_rental_unit: tenantOccupancy.related_rental_unit.id,
          related_tenant: tenantOccupancy.related_tenant.id,
          amount: amount,
          related_month: month,
          date_paid: datePaid,
          payment_method: paymentMethod,
          is_initial_payment: initialPayment,
          has_security_deposit: hasSecurityDeposit,
          next_payment_date: nextPaymentDate,
        },
        { abortEarly: false }
      );
    } catch (error) {
      const newError = {};
      error.inner.forEach((err) => {
        newError[err.path] = err.message;
      });
      setErrors(newError);
      return;
    }
    try {
      const response = await axios.post(`${baseURL}/tenants/payments/custom`, {
        related_rental_unit: tenantOccupancy.related_rental_unit.id,
        related_tenant: tenantOccupancy.related_tenant.id,
        amount: amount,
        related_month: month,
        date_paid: datePaid,
        payment_method: paymentMethod,
        is_initial_payment: initialPayment,
        has_security_deposit: hasSecurityDeposit,
        next_payment_date: nextPaymentDate,
      });
      dispatch(setRefreshCount());
      setOpenEditDialog(false);
      // setOpenSnack({ ...openSnack, open: true});
      // setSeverity("success");
      // setMessage("Tenant Added Successfully");
    } catch (err) {
      // setOpenSnack({ ...openSnack, open: true});
      // setSeverity("error");
      // setMessage("Tenant Addition failed, Please check data provided");
    }
  };

  const handleSubmitDeleteTenantPayment = async () => {
    
    try {
      // const response = await axios.post(`${baseURL}/tenants/payments/custom`);
      // dispatch(setRefreshCount());
      // setOpenDeleteDialog(false);
      // setOpenSnack({ ...openSnack, open: true});
      // setSeverity("success");
      // setMessage("Tenant Added Successfully");
    } catch (err) {
      // setOpenSnack({ ...openSnack, open: true});
      // setSeverity("error");
      // setMessage("Tenant Addition failed, Please check data provided");
    }
  };
  

  return (
    <Box m="20px">
      <TitleContainer>
        <Typography variant="h5" style={{marginBottom: 0,fontWeight: "bold",fontSize: "25px",paddingLeft: 5, }}>Tenant Payments for{" "}{tenantOccupancy.related_tenant.first_name+ " " +tenantOccupancy.related_tenant.last_name +" in Unit " +tenantOccupancy.related_rental_unit.unit_name +"-" +tenantOccupancy.related_rental_unit.related_rental.rental_name}</Typography>
      </TitleContainer>
      <Box sx={{ p: 3 }}>
        <FlexBetween>
          <BasicCard name="Days Left" value={activePeriods.days_left} />
          <BasicCard name="Unit Rent" value={activePeriods.unit_rent} />
          <BasicCard name="Current Outstanding Balance" value={currencyFormatter(parseFloat(activePeriods.money))}/>
          <BasicCard name="Total Complete Months" value={activePeriods.completed_months} />
          <Button variant="contained" color="primary" sx={{ height: 56, width: 200 }} onClick={handleOpenDialog}>Record Payment</Button>
        </FlexBetween>

        <Box sx={{ mt: 5 }}>
          {isLoadingActivePeriods ? (
            <DummyTable/>
          ):(
            <LandlordTenantPaymentsTable
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              paymentData={activePeriods.schedules} // Using dummy data
              handleDeletePayment={handleOpenDeleteDialog}
              handleEditPayment={handleOpenEditDialog}
            />
          )}
        </Box>
      </Box>

      <FormDialog
        open={openDialog}
        title="Add Payment"
        text="Fill out the details below to add a new payment."
        content={
          <LandlordTenantPaymentsForm
            monthOptions={monthsAndYears}
            isLoadingMonthsAndYears={isLoadingMonthsAndYears}
            handleChangeAmount={handleChangeAmount}
            handleChangeMonth={handleChangeMonth}
            month={month}
            handleChangeDatePaid={handleChangeDatePaid}
            handleChangeNextPaymentDate={handleChangeNextPaymentDate}
            handleChangePaymentMethod={handleChangePaymentMethod}
            paymentMethod={paymentMethod}
            datePaid={datePaid}
            handleInitialPayment={handleInitialPayment}
            initialPayment={initialPayment}
            handleSecurityDeposit={handleSecurityDeposit}
            hasSecurityDeposit={hasSecurityDeposit}
            errors={errors}
          />
        }
        handleClose={handleCloseDialog}
        handleSubmit={handleSubmitTenantPayment}
        isSubmittingData={isSubmittingData}
      />
      <FormDialog
        open={openEditDialog}
        title="Edit Payment"
        text="Fill out the details below to add a new payment."
        content={
          <EditTenantPaymentForm
            paymentData={newPayment}
            monthOptions={monthsAndYears}
            isLoadingMonthsAndYears={isLoadingMonthsAndYears}
            handleInputChange={handleInputChange}
            errors={errors}
          />
        }
        handleClose={handleCloseEditDialog}
        handleSubmit={handleSubmitEdittedTenantPayment}
        isSubmittingData={isSubmittingData}
      />
      <FormDialog
        open={openDeleteDialog}
        title="Delete Payment"
        content="Are you sure you want to delete this Payment?"
        handleClose={handleCloseDeleteDialog}
        handleSubmit={handleSubmitDeleteTenantPayment}
        isSubmittingData={isSubmittingData}
      />
      <TopSnack
        vertical={openSnack.vertical}
        horizontal={openSnack.horizontal}
        open={openSnack.open}
        handleCloseSnack={handleCloseSnack}
        message={message}
        severity={severity}
      />
    </Box>
  );
};

export default LandlordTenantsPayments;
