import React from "react";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentTab } from "state";
import CustomTab from "components/Tabs/Tab";
import Landlords from "scenes/GeneralLandlords/Landlords";
import ReferredLandlords from "scenes/GeneralLandlords/ReferredLandlords";
import InvisibleLandlords from "./InvisibleLandlords";
import RegisteredLandlords from "./RegisteredLandlords";
import OnboardingLandlords from "./PendingOnboardingLandlords";

const GeneralLandlordsTab = () => {
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['generalLandlordsTab']) );
    const handleChange = (event, newValue) => {
        setValue(newValue);
        dispatch(setCurrentTab({ name: "generalLandlordsTab", tab: newValue }));
    };

    const tabComponents = [
      { Component: <Landlords />, label: "All Landlords", index: 0 },
      { Component: <OnboardingLandlords />, label: "Pending Onboarding", index: 1 },
      { Component: <RegisteredLandlords />, label: "Registerred Landlords", index: 2 },
      { Component: <ReferredLandlords/>, label: "Reffered Landlords", index: 3 },
      { Component: <InvisibleLandlords />, label: "Invisible Landlords", index: 4 },
    ]; 


    return (
        <Box>
            <CustomTab tabComponents={tabComponents} value={value} handleChange={handleChange}/> 
        </Box>
    )

}

export default GeneralLandlordsTab;