import React, { useState } from "react";
import {Paper, Table, TableBody, Box, Collapse, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography, IconButton, Button} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions"; 
import Chip from "@mui/material/Chip";
import { StyledTableCell, StyledTableRow } from "./Styles/TableAndCellStyles";
import { useNavigate } from 'react-router-dom'; // for navigation
import PaymentIcon from '@mui/icons-material/Payment';
import { useDispatch } from "react-redux";
import { setTenantPayment } from "state";
import { formatDateString } from "utilities/date";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import axios from "axios";
import { baseURL } from "services/API";
import moment from "moment";
import { withoutSymbolFormatter, currencyFormatter} from "utilities/currencyFormatter";


const LandlordTenantPaymentsTable = ({ paymentData, handleDeletePayment, handleEditPayment }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const paymentMethods = { 1: "CASH", 2: "MOBILE MONEY", 3: "MOMO PAY" };

  const renderStatusPills = (status) => {
		switch (status) {
			case false:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffebeb", fontSize:"10px", fontWeight:700 }} label="INCOMPLETE" size="small" color="warning" />;
			case true:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ebefff", fontSize:"10px", fontWeight:700 }} label="COMPLETE" size="small" color="primary" />;
		}
	};

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleShowReceipt = (payment) => {
    dispatch(setTenantPayment(payment))
    navigate(`/tenant-receipts`);
  }

  function DetailDropDown(props) {
    const { row, index } = props;
    const [open, setOpen] = useState(false);
    const [itemData, setItemData] = useState([]);

    const handleClickView = async (id) => {
      try {
        const response = await axios.get(
          `${baseURL}/tenants/completed_payments/payments_list?period_id=${id}`
        );
        setItemData(response.data.data);
      } catch (err) {}
    };

    const handleClickViewClose = async (e) => {};
    
    return (
      <>
        <StyledTableRow key={row.id}>
          <StyledTableCell align="left">{row.related_active_period.related_month.month} {row.related_active_period.related_month.year}</StyledTableCell>
          <StyledTableCell align="left">{formatDateString(row.related_active_period.date_started)}</StyledTableCell>
          <StyledTableCell align="left">{formatDateString(row.related_active_period.date_ended)}</StyledTableCell>
          <StyledTableCell component="th" scope="row">{currencyFormatter(parseInt(row.outstanding_balance))}</StyledTableCell>
          <StyledTableCell align="left">{renderStatusPills(row.is_completed)}</StyledTableCell>
          <StyledTableCell style={{ textAlign:"center" }}>
            <IconButton aria-label="expand row"  onClick={(e) => {   setOpen(!open);   handleClickView(row.related_active_period.id); }}> {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
          </StyledTableCell>
          <StyledTableCell style={{ textAlign: "center" }}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              sx={{ color:"blue" }}
              onClick={(e) => handleShowReceipt(row)}
            >
              <PaymentIcon color="blue" sx={{ mr: 1 }}/>
              View Receipt
            </Button>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow key={row.id}>
          <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: "10px 10px 10px 10px", width:"100%" }}>
                <Typography variant="h4" gutterBottom component="div"> Payment Details</Typography>
                {itemData ? 
                  <Box sx={{ display: "flex", flexDirection: "column", margin: "15px"  }}>
                    <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                    <TableHead>
                      <StyledTableRow>
                      <StyledTableCell style={{ minWidth: 50,padding: "6px 20px" }}>Payment Date</StyledTableCell>
                      <StyledTableCell style={{ minWidth: 150,padding: "6px 20px", textAlign: "center"  }}>Amount Paid</StyledTableCell>
                      <StyledTableCell style={{ minWidth: 50,padding: "6px 20px", textAlign: "center"  }}>Mode of Payment</StyledTableCell>
                      {/* <StyledTableCell style={{ minWidth: 50,padding: "6px 20px", textAlign: "center" , textAlign:"center" }}>Action</StyledTableCell> */}
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {itemData.length === 0 ? (
                      <StyledTableRow>
                        <StyledTableCell colSpan={4} style={{ textAlign: "center" }}>
                          <Typography variant="h5">No payments recorded</Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                      ) : (
                      itemData
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                        return (
                          <StyledTableRow key={row.id}>
                          <StyledTableCell style={{ padding: "6px 20px" }}>{moment(row.date_paid).format('LL')}</StyledTableCell>
                          <StyledTableCell style={{ padding: "6px 20px", textAlign: "center" }}>{withoutSymbolFormatter(parseFloat(row.amount))}</StyledTableCell>
                          <StyledTableCell style={{ padding: "6px 20px", textAlign: "center"  }}>{paymentMethods[row.payment_method]}</StyledTableCell>
                          {/* <StyledTableCell style={{ padding: "6px 20px", textAlign: "center"  }}>
                            <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(e) => {handleDeletePayment(row)}}
                            >
                            <PaymentIcon color="action" sx={{ mr: 1 }} />
                            Delete Payment
                            </Button>
                          </StyledTableCell> */}
                          </StyledTableRow>
                        );
                        })
                      )}
                    </TableBody>
                    </Table>
                  </Box> : (
                    <Box sx={{ width:"100%", display:"flex", justifyContent:"center" }}>
                    <h4>Payment Details Loading.......</h4>
                    </Box>
                  )
                }

              </Box>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </>
    );
  }

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="left">Period Paid For</StyledTableCell>
                <StyledTableCell align="left">Month Start Date</StyledTableCell>
                <StyledTableCell align="left">Month End Date</StyledTableCell>
                <StyledTableCell>Balance</StyledTableCell>
                <StyledTableCell align="left">Is Full Payment</StyledTableCell>
                <StyledTableCell align="center">Payments Made</StyledTableCell>
                <StyledTableCell align="left">Actions</StyledTableCell>
                </StyledTableRow>
            </TableHead>
            <TableBody>
              {(paymentData > 0 ? paymentData.slice(page * rowsPerPage,page * rowsPerPage + rowsPerPage) : paymentData ).map((payment, index) => (
                <DetailDropDown row={payment} index={index}/>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, { label: "All", value: -1 }]}
                  colSpan={7}
                  count={paymentData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default LandlordTenantPaymentsTable;
