import React, { useState } from "react";
import { Button, Grid, Typography, Box } from "@mui/material";
import LandlordPropertiesTable from "components/Tables/LandlordPropertiesTable";
import { styled } from "@mui/material/styles";
import { useLandlordProperties } from "services/hooks/rentals";
import { useSelector, useDispatch } from "react-redux";
import { FlexEnd } from "components/FlexBox/FlexBox";
import FormDialog from "components/modal";
import RentalsForm from "components/Forms/RentalsForm";
import { setPropertyId, setRefreshCount } from "state";
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import axios from "axios";
import { baseURL } from "services/API";
import TopSnack from "components/TopSnack";


const TitleContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const LandlordProperties = () => {
  const landlord = useSelector((state) => state.landlord);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {landlordProperties,loading, error, setNewData} = useLandlordProperties(landlord.id);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rentalName, setRentalName] = React.useState("");
  const [rentalType, setRentalType] = React.useState("");
  const [rentalLocation, setRentalLocation] = React.useState("");
  const [rentalCity, setRentalCity] = React.useState("");
  const [security, setSecurity] = React.useState(false);
  const [gym, setGym] = React.useState(false);
  const [hospital, setHospital] = React.useState(false);
  const [mainRoad, setMainRoad] = React.useState(false);
  const [elevator, setElevator] = React.useState(false);
  const [swimmingPool, setSwimmingPool] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState({})
  const [deleteMessage, setDeleteMessage] = React.useState("");
  const [deleteButton, setDeleteButton] = React.useState(false);
  const [errors, setErrors]= React.useState({});
  const [openSnack, setOpenSnack] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [severity, setSeverity] = React.useState("");
  const [message, setMessage] = React.useState("");
  
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack({ ...openSnack, open: false });
  };

  const handleNavigateUnits = (property_id) => {
    dispatch(setPropertyId(property_id));
    navigate("/propertyDetailsTab");
  };

  const handleRentalNameChange = (event) => {
		const {value} = event.target
		setRentalName(value);
		if (!value.trim()) {
      		setErrors((prev) => ({ ...prev, 'rental_name': `Property Name is required`}));
		} else {
			setErrors((prev) => ({ ...prev, "rental_name": "" }));
		}
	};

	const handleChangeRentalType = (event) => {
		const { value } = event.target;
		setRentalType(value);
		if (!value.trim()) {
      		setErrors((prev) => ({ ...prev, 'rental_type': `Select the property type`}));
		} else {
			setErrors((prev) => ({ ...prev, "rental_type": "" }));
		}
	};

	const handleRentalLocationChange = (event) => {
		const { value } = event.target;
		setRentalLocation(value);
		if (!value.trim()) {
      		setErrors((prev) => ({ ...prev, 'location_county': `Input a parish or county in the selected City`}));
		} else {
			setErrors((prev) => ({ ...prev, "location_county": "" }));
		}
	};


  const handleRentalCityChange = (event) => {
    const { value } = event.target;
		setRentalCity(value);
		if (!value.trim()) {
      		setErrors((prev) => ({ ...prev, 'location': `Input a City`}));
		} else {
			setErrors((prev) => ({ ...prev, "location": "" }));
		}
  };

  const handleSecurityCheck = (event) => {
    setSecurity(event.target.checked);
  };

  const handleSwimmingPoolCheck = (event) => {
    setSwimmingPool(event.target.checked);
  };

  const handleHospitalCheck = (event) => {
    setHospital(event.target.checked);
  };

  const handleMainRoadCheck = (event) => {
    setMainRoad(event.target.checked);
  };

  const handleElevatorCheck = (event) => {
    setElevator(event.target.checked);
  };

  const handleGymCheck = (event) => {
    setGym(event.target.checked);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setErrors({});
  };

  const handleClickOpenDelete = async (item) => {
    setSelectedItem(item);
    try {
      const response = await axios.get(`${baseURL}/landlords/rentals/delete?rental_id=${item.id}`);
      if (response.data.status===200) {
        setDeleteMessage("Are you sure you want to delete this property?");
        setDeleteButton(true);
      }

      if (response.data.status===404) {
        setDeleteMessage(
        <>
          <Typography fontWeight={"bold"} color="red">This property can not currenty be deleted.</Typography>
          <Typography>Please remove any current tenants and try again</Typography>
        </>);
        setDeleteButton(false);
      }
      setAnchorEl(null);
      setOpenDelete(true);
    } catch (err) {
      setOpenSnack({ ...openSnack, open: true });
      setSeverity("error");
      setMessage("Property Delete failed, Please check property details");
    }
    
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSubmitDelete = async () => {
    try {
      const response = await axios.delete(`${baseURL}/landlords/rentals/delete?rental_id=${selectedItem.id}`);

      setOpenSnack({ ...openSnack, open: true });
      setSeverity("success");
      setMessage("Property Deleted Successfully");
      setOpenDelete(false);
      setNewData(true);
      dispatch(setRefreshCount());
    } catch (err) {
      setOpenSnack({ ...openSnack, open: true });
      setSeverity("error");
      setMessage("Property Delete failed, Please check property details");
    }
  };


  const propertySchema = yup.object().shape({
		rental_name: yup.string().required("Please enter the property name"),
		location: yup.string().required("Input a City"),
    location_county: yup.string().required("Input a parish or county in the selected City"),
		rental_type: yup.string().required("Select the property type"),
	});

  const handleRentalFormSubmit = async () => {

    try {
			await propertySchema.validate(
        {
          rental_name: rentalName,
          rental_type: rentalType,
          location: rentalCity,
          location_county: rentalLocation,
          has24_security: security,
          has_gym: gym,
          has_swimming_pool: swimmingPool,
          has_elevator: elevator,
          hospital_access: hospital,
          main_road_access: mainRoad,
        },
        { abortEarly: false }
      );
		} catch (error) {
			const newError = {}
			error.inner.forEach(err => {
				newError[err.path]=err.message
			})
			setErrors(newError)
			return
		}

    try {
      const response = await axios.post(`${baseURL}/landlords/rentals`, {
        related_landlord: landlord.id,
        rental_name: rentalName,
        rental_type: rentalType,
        location: rentalCity,
        location_county: rentalLocation,
        has24_security: security,
        has_gym: gym,
        has_swimming_pool: swimmingPool,
        has_elevator: elevator,
        hospital_access: hospital,
        main_road_access: mainRoad,
      });

      setOpenSnack({...openSnack, open: true});
      setSeverity("success");
      setMessage("Property Added Successfully");
      setOpen(false);
      dispatch(setRefreshCount());
      handleNavigateUnits(response.data.data.id);
    } catch (err) {
      setOpenSnack({ ...openSnack, open: true });
      setSeverity("error");
      setMessage("Property Addition failed, Please check property details");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error loading properties</Typography>;

  return (
    <Box m="20px">
      <FlexEnd sx={{ marginBottom:"10px",  flexDirection:"row", justifyContent:"flex-end" }}>
        <Button onClick={()=>handleClickOpen()} variant="contained" color="primary">
          Add Property
        </Button>
      </FlexEnd>
      {landlordProperties && (
        <LandlordPropertiesTable
          properties={landlordProperties}
          page={page}
          rowsPerPage={rowsPerPage}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleClickOpenDelete={handleClickOpenDelete}
          handleNavigateUnits={handleNavigateUnits}
        />
      )}
      <FormDialog
        open={open}
        handleClose={handleClose}
        enableActions={true}
        content={
          <RentalsForm
            rentalType={rentalType}
            rentalCity={rentalCity}
            handleRentalNameChange={handleRentalNameChange}
            handleChangeRentalType={handleChangeRentalType}
            handleRentalLocationChange={handleRentalLocationChange}
            handleRentalCityChange={handleRentalCityChange}
            handleSecurityCheck={handleSecurityCheck}
            handleGymCheck={handleGymCheck}
            handleSwimmingPoolCheck={handleSwimmingPoolCheck}
            handleElevatorCheck={handleElevatorCheck}
            handleHospitalCheck={handleHospitalCheck}
            handleMainRoadCheck={handleMainRoadCheck}
            errors={errors}
            security={security}
            gym={gym}
            swimmingPool={swimmingPool}
            elevator={elevator}
            hospital={hospital}
            mainRoad={mainRoad}
          />
        }
        title={"Add all Property Details"}
        handleSubmit={handleRentalFormSubmit}
        buttonText="submit"
      />
      <FormDialog
        open={openDelete}
        handleClose={handleCloseDelete}
        enableActions={deleteButton}
        content={deleteMessage}
        title={"Delete all Property Details"}
        handleSubmit={handleSubmitDelete}
        buttonText="Yes, Delete"
      />

      <TopSnack
        vertical={openSnack.vertical}
        horizontal={openSnack.horizontal}
        open={openSnack.open}
        handleCloseSnack={handleCloseSnack}
        message={message}
        severity={severity}
      />
    </Box>
  );
};

export default LandlordProperties;
